import React from 'react'

import Footer from '../components/Footer/Footer'
import TermsUsingCredit from '../components/TermsUsingCredit/TermsUsingCredit'
import FAQ from '../components/FAQ/FAQ'
import ApplicationForm from './../components/Forms/ApplicationForm'
import PaydayLoanCalculate from './components/PaydayLoan/PaydayLoanCalculate'
import PaydayLoanWhyProfitableTake from './components/PaydayLoan/PaydayLoanWhyProfitableTake'
import PaydayLoanHowGet from './components/PaydayLoan/PaydayLoanHowGet'
import PaydayLoanGiveCredit from './components/PaydayLoan/PaydayLoanGiveCredit'
import 'swiper/css'

const PaydayLoan = () => {
    return (
        <div className="payday_loan">
            <PaydayLoanCalculate />

            <PaydayLoanWhyProfitableTake />

            {/* <div className="get_loan payday_loan_from_us">
                <div className="container">
                    <div className="get_loan__list">
                        <div className="get_loan__item">
                            <img src={fastestDeliveryIcon} alt="icon 1" />
                            <div className="get_loan__item_text">Подай  заявку на позику, і  Гроші 24/7 будуть на твоій картці вже через 5 хвилин.</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={phoneIcon} alt="icon 2" />
                            <div className="get_loan__item_text">100%<br /> on-line</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={timeIsMoneyIcon} alt="icon 3" />
                            <div className="get_loan__item_text">Кредит можна<br /> пролонгувати</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={percentIcon} alt="icon 4" />
                            <div className="get_loan__item_text">Діє програма<br /> лояльності</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={shieldIcon} alt="icon 5" />
                            <div className="get_loan__item_text">Завдяки автоматизованій<br /> обробці та<br /> персоналізованому підходу.</div>
                        </div>
                    </div>
                </div>
            </div> */}

            <ApplicationForm />

            <PaydayLoanHowGet />

            <PaydayLoanGiveCredit />

            <TermsUsingCredit />

            <FAQ />

            <Footer />
        </div>
    );
};

export default PaydayLoan;