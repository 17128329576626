import Input from '@mui/material/Input'
import {
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Slider,
	Table, TableBody, TableCell,
	TableContainer,
	TableHead, TableRow
} from '@mui/material'
import moment from 'moment'
import React from 'react'
import toFixNumber from '../../../lib/helpers/toFixNumber'
import Header from '../../../components/Header/Header'
import Button from '../../../components/Common/Button'
import { Link } from "react-router-dom"
import informationCreditPaymentOfEducation
	from '../../../files/Інформація_про_послугу_надання_кредиту_на_оплату_навчання.pdf'
import checkedItem from '../../../assets/checked_item.svg'
import lendingConditionsMoney from '../../../assets/lending_conditions_money.svg'
import lendingConditionsCalendar from '../../../assets/lending_conditions_calendar.svg'
import lendingConditionsMoneyVertical from '../../../assets/lending_conditions_money_vertical.svg'
import lendingConditionsFree from '../../../assets/lending_conditions_free.svg'
import lendingConditionsPie from '../../../assets/lending_conditions_pie.svg'
import lendingConditionsCard from '../../../assets/lending_conditions_card.svg'
import useCalculate from '../../../lib/hooks/useCalculate'
import { roundNumber } from '../../../lib/helpers/roundValues'

const StudyLoanCalculate = () => {
	const {
		total,
		principal,
		interest,
		apr,
		setLoanTerm,
		setLoanAmount,
		creditPolicies,
		createShopRequest,
		promocode,
		setPromocode,
		sendPromoCodeLoan,
		paymentScheduleCalculate
	} = useCalculate({
		creditProductId: 7,
		typeSendLoanTerm: 'month',
		sendCalculate: false
	});

	return (
		<div className="top_background__study_loan">
			<div className="container">
				<Header activePageName="study-loan" />
				<h1 className="title">Кредит на навчання</h1>
				<div className="study_loan__wrapper">
					<div className="payday_loan__left">
						<p className="study_loan__title">
							Рухайтеся до своєї мети разом з Groshi 24/7
						</p>
						<Button onClick={createShopRequest} pink>
							ЗАРЕЄСТРУВАТИСЬ
						</Button>
					</div>
					<div className="car_loan__calculate">
						{/* left */}
						<div className="car_loan__calculate_left loan_calculate" style={{ paddingTop: '30px' }}>
							<div className="loan_calculate__slider" style={{ marginTop: 0 }}>
								<div className="loan_calculate__slider_title">Сума кредиту, грн</div>
								<Slider
									defaultValue={creditPolicies?.loan_amount_limit_from ? creditPolicies?.loan_amount_limit_from : 0}
									aria-label="Default"
									valueLabelDisplay="auto"
									min={creditPolicies?.loan_amount_limit_from ? creditPolicies?.loan_amount_limit_from : 0}
									max={creditPolicies?.loan_amount_limit_to ? creditPolicies?.loan_amount_limit_to : 0}
									onChange={(e) => setLoanAmount(e.target.value)}
								/>
								<div className="loan_calculate__slider_block">
									<div className="loan_calculate__slider_desc">
										{creditPolicies?.loan_amount_limit_from
											? creditPolicies?.loan_amount_limit_from
											: 0
										} ₴
									</div>
									<div className="loan_calculate__slider_desc">
										{creditPolicies?.loan_amount_limit_to
											? creditPolicies?.loan_amount_limit_to
											: 0
										} ₴
									</div>
								</div>
							</div>
							<div className="loan_calculate__slider">
								<div className="loan_calculate__slider_title">Строк навчання, місяців</div>
								<Slider
									defaultValue={creditPolicies?.loan_term_limit_from ? creditPolicies?.loan_term_limit_from : 0}
									aria-label="Default"
									valueLabelDisplay="auto"
									min={creditPolicies?.loan_term_limit_from
										? creditPolicies?.loan_term_limit_from < 30 ? 1 : (creditPolicies?.loan_term_limit_from / 30).toFixed()
										: 0}
									max={creditPolicies?.loan_term_limit_to
										? creditPolicies?.loan_term_limit_to < 30 ? 1 : (creditPolicies?.loan_term_limit_to / 30).toFixed()
										: 0}
									onChange={(e) => setLoanTerm(e.target.value)}
								/>
								<div className="loan_calculate__slider_block">
									<div className="loan_calculate__slider_desc">
										{creditPolicies?.loan_term_limit_from
											? creditPolicies?.loan_term_limit_from < 30 ? 1 : (creditPolicies?.loan_term_limit_from / 30).toFixed()
											: 0
										} міс.
									</div>
									<div className="loan_calculate__slider_desc">
										{creditPolicies?.loan_term_limit_to
											? creditPolicies?.loan_term_limit_to < 30 ? 1 : (creditPolicies?.loan_term_limit_to / 30).toFixed()
											: 0
										} міс.
									</div>
								</div>
							</div>

							<FormControl sx={{ marginTop: '25px' }} fullWidth>
								<InputLabel
									id="how-get-tranches-select-label"
									sx={{
										'&.Mui-focused': {
											color: '#107275'
										}
									}}
								>
									Частота оплати за навчання
								</InputLabel>
								<Select
									labelId="how-get-tranches-select-label"
									id="how-get-tranches"
									value={1}
									label="Частота оплати за навчання"
									onChange={(e) => {}}
									sx={{
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: '#107275'
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: '#107275'
										},
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#107275'
										}
									}}
								>
									<MenuItem value={1}>1 раз на місяць</MenuItem>
									<MenuItem value={2}>3 рази на місяць</MenuItem>
									<MenuItem value={3}>6 разів на місяць</MenuItem>
									<MenuItem value={4}>передоплата</MenuItem>
								</Select>
							</FormControl>

							<FormControl sx={{ marginTop: '25px' }} fullWidth>
								<InputLabel
									id="prepayment-select-label"
									sx={{
										'&.Mui-focused': {
											color: '#107275'
										}
									}}
								>
									Передоплата, %
								</InputLabel>
								<Select
									labelId="prepayment-select-label"
									id="prepayment"
									value={1}
									label="Передоплата, %"
									onChange={(e) => {}}
									sx={{
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: '#107275'
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: '#107275'
										},
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#107275'
										}
									}}
								>
									<MenuItem value={1}>0%</MenuItem>
									<MenuItem value={2}>15%</MenuItem>
									<MenuItem value={3}>20%</MenuItem>
									<MenuItem value={4}>інше</MenuItem>
								</Select>
							</FormControl>

							<FormControl sx={{ marginTop: '25px' }} fullWidth>
								<Input
									size="small"
									placeholder="Введіть промокод"
									id="standard-basic"
									variant="outlined"
									value={promocode}
									onChange={(e) => setPromocode(e.target.value)}
									sx={{
										'& .MuiInputBase-input::placeholder': {
											color: 'black',
											opacity: 1
										},
										'&:before': {
											borderBottomColor: promocode ? '#f07c93' : '#000'
										},
										'&:hover:not(.Mui-disabled):before': {
											borderBottomColor: '#0c6a6c'
										},
										'&.Mui-focused:after': {
											borderBottomColor: '#f07c93'
										}
									}}
								/>
							</FormControl>
							<FormControl sx={{ marginTop: '25px' }} fullWidth>
								<Button onClick={sendPromoCodeLoan} green>Показати графік платежів</Button>
							</FormControl>
						</div>

						{/* right */}
						<div className="car_loan__calculate_right loan_calculate__right">
							<div className="loan_calculate__right__block">
								<div className="loan_calculate__right__title">Загальні витрати за кредитом, грн</div>
								<div className="loan_calculate__right__number">{toFixNumber(principal)} грн</div>
							</div>
							<div className="loan_calculate__right__block">
								<div className="loan_calculate__right__title">Сума платежу за розрахунковий період, грн</div>
								<div className="loan_calculate__right__number">{toFixNumber(interest)} грн</div>
							</div>
							<div className="loan_calculate__right__block">
								<div className="loan_calculate__right__title">Загальна вартість кредиту, грн</div>
								<div className="loan_calculate__right__number">{toFixNumber(total)} грн</div>
							</div>
							<div className="loan_calculate__right__block">
								<div className="loan_calculate__right__title">Реальна річна процентна ставка, %</div>
								<div className="loan_calculate__right__number">
									{toFixNumber(1.9 * 30 * 12)}%
									{/*{toFixNumber(apr * 100)} %*/}
								</div>
							</div>
							<a href="#application-form-scroll">
								<Button onClick={() => {}} style={{ margin: '10px 0', width: '100%' }} pink>
									Залишити заявку
								</Button>
							</a>
						</div>
						<div className="car_loan__calculate_info">
							{paymentScheduleCalculate?.length > 0 && (
								<>
									<TableContainer component={Paper}>
										<Table sx={{ minWidth: 650 }} aria-label="simple table">
											<TableHead>
												<TableRow>
													<TableCell sx={{ fontWeight: 'bold' }}>Дата</TableCell>
													<TableCell sx={{ fontWeight: 'bold' }} align="right">Сума кредиту</TableCell>
													<TableCell sx={{ fontWeight: 'bold' }} align="right">Відсотки по кредиту</TableCell>
													<TableCell sx={{ fontWeight: 'bold' }} align="right">Комісія</TableCell>
													<TableCell sx={{ fontWeight: 'bold' }} align="right">Всього</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{paymentScheduleCalculate.map(({ id, date, principal, interest, commission }) => (
													<TableRow
														key={`payment-schedule-calculate-item-${id}`}
														sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
													>
														<TableCell component="th" scope="row">{moment(date).format('DD.MM.YYYY')}</TableCell>
														<TableCell align="right">{roundNumber(principal)}</TableCell>
														<TableCell align="right">{roundNumber(interest)}</TableCell>
														<TableCell align="right">{roundNumber(commission)}</TableCell>
														<TableCell align="right">{roundNumber(+principal + +interest + +commission)}</TableCell>
													</TableRow>
												))}
												<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
													<TableCell sx={{ fontWeight: 'bold' }} component="th" scope="row">Всього</TableCell>
													<TableCell align="right">
														{paymentScheduleCalculate.reduce((acc, { principal }) => roundNumber(+principal + +acc), 0)}
													</TableCell>
													<TableCell align="right">
														{paymentScheduleCalculate.reduce((acc, { interest }) => roundNumber(+interest + +acc), 0)}
													</TableCell>
													<TableCell align="right">
														{paymentScheduleCalculate.reduce((acc, { commission }) => roundNumber(commission + +acc), 0)}
													</TableCell>
													<TableCell align="right">
														{
															paymentScheduleCalculate.reduce((acc, { principal, interest, commission }) =>
																roundNumber(+principal + +interest + +commission + +acc),
																0)
														}
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
									<Button onClick={createShopRequest} style={{ margin: '10px 0', width: '100%' }} pink>
										Оформити кредит
									</Button>
								</>
							)}
							<Link to={informationCreditPaymentOfEducation} className="car_loan__calculate_info__link" target="_blank">
								Інформація про істотні характеристики послуги надання кредитів на навчання
							</Link>
							Фінансова установа розміщує на сторінці з інформацією про послугу з надання споживчого кредиту приклади результатів розрахунків калькулятора, до яких включає інформацію про всі припущення, використані для розрахунку загальних витрат за послугою з надання споживчого кредиту
						</div>
					</div>
				</div>
				<div className="study_loan__block_bottom_calculate">
					<Button
						onClick={createShopRequest}
						style={{ marginTop: '10px', paddingLeft: '45px', paddingRight: '45px' }}
						pink
					>
						ЗАРЕЄСТРУВАТИСЯ
					</Button>
					<div className="study_loan_its_credit_need_for">
						<div className="study_loan_its_credit_need_for__title">
							Цей кредит потрібен тим, хто хоче:
						</div>
						<div className="study_loan_its_credit_need_for__list">
							<div className="study_loan_its_credit_need_for__item">
								<img src={checkedItem} alt="checked item" />
								здобути середню <br />
								спеціальну або вищу<br />
								освіту в Україні
							</div>
							<div className="study_loan_its_credit_need_for__item">
								<img src={checkedItem} alt="checked item" />
								освоїти нову професію
							</div>
							<div className="study_loan_its_credit_need_for__item">
								<img src={checkedItem} alt="checked item" />
								навчатися на курсах<br />
								(IT, англійська, web-дизайн,<br />
								трейдинг, SMM тощо)
							</div>
						</div>
					</div>
				</div>

				<div className="study_loan_lending_conditions">
					<div className="title">Умови кредитування</div>
					<div className="study_loan_lending_conditions__list">
						<div className="study_loan_lending_conditions__item">
							<div className="study_loan_lending_conditions__item_top">
								сума кредиту<br />
								<b>від 10 000 грн</b><br />
								<b>до 50 000 грн</b>
							</div>

							<div className="study_loan_lending_conditions__item_bottom">
								<img src={lendingConditionsMoney} alt="lending Conditions Money"/>
							</div>
						</div>
						<div className="study_loan_lending_conditions__item">
							<div className="study_loan_lending_conditions__item_top">
								строк кредитування<br />
								<b>від 1-го до 6-ти</b><br />
								<b>місяців</b>
							</div>

							<div className="study_loan_lending_conditions__item_bottom">
								<img src={lendingConditionsCalendar} alt="lending Conditions Calendar"/>
							</div>
						</div>
						<div className="study_loan_lending_conditions__item">
							<div className="study_loan_lending_conditions__item_top">
								надається<br />
								<b>в гривні</b>
							</div>

							<div className="study_loan_lending_conditions__item_bottom">
								<img src={lendingConditionsMoneyVertical} alt="lending Conditions Money Vertical"/>
							</div>
						</div>
						<div className="study_loan_lending_conditions__item">
							<div className="study_loan_lending_conditions__item_top">
								оформлення<br />
								кредиту<br />
								<b>безкоштовне</b><br />
							</div>

							<div className="study_loan_lending_conditions__item_bottom">
								<img src={lendingConditionsFree} alt="lending Conditions Free"/>
							</div>
						</div>
						<div className="study_loan_lending_conditions__item">
							<div className="study_loan_lending_conditions__item_top">
								погашення –<br /> <b>щомісячно</b>
							</div>

							<div className="study_loan_lending_conditions__item_bottom">
								<img src={lendingConditionsPie} alt="lending Conditions Pie"/>
							</div>
						</div>
						<div className="study_loan_lending_conditions__item">
							<div className="study_loan_lending_conditions__item_top">
								на рахунок в<br /> <b>українському банку</b>
							</div>

							<div className="study_loan_lending_conditions__item_bottom">
								<img src={lendingConditionsCard} alt="lending Conditions Card"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StudyLoanCalculate;