import React from "react";
import { Link } from "react-router-dom";
import Button from "../Common/Button";
import "./FAQ.scss";

const FAQ = ({ backgroundColorWrapper = '#fcf0eb' }) => {
    return (
        <div className="faq" style={{ backgroundColor: backgroundColorWrapper }}>
            <div className="container">
                <div className="title">FAQ</div>
                <div className="faq__list">
                    <div className="faq__item">
                        <div className="faq__header">На яку суму можна отримати кредит на картку на сайті Groshi 24/7?</div>
                        <div className="faq__body">
                            Ви можете отримати кредит онлайн на суму від 1 000,00 грн. до 50 000,00 грн. Термін кредитування може бути обраний у межах від 7 днів до 120 днів, залежно від умов банку або фінансової організації, що надає кредит.
                        </div>
                    </div>
                    <div className="faq__item">
                        <div className="faq__header">Що потрібно для оформлення мікрозайму?</div>
                        <div className="faq__body">
                            Для отримання кредиту, зазвичай потрібно наступне:
                            1. Комп'ютер або мобільний телефон з доступом до Інтернету
                            2. Банківська картка будь-якого банку України, оформлена на ваше ім'я. Вона може використовуватися для отримання коштів або погашення кредиту.
                            3. Паспорт для ідентифікації вас як заявника кредиту.
                            4. Ідентифікаційний код - це унікальний ідентифікатор, присвоєний кожному громадянину України.
                        </div>
                    </div>
                    <div className="faq__item">
                        <div className="faq__header">До якого віку дають споживчий кредит?</div>
                        <div className="faq__body">
                            Вимоги до позичальників: громадянство України, вік від 18 до 60 років, відсутність значних прострочень у кредитній історії.
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '60px' }}>
                    <Link to='/faq'>
                        <Button pink>Далі</Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default FAQ;