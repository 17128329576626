import axios from "axios";
/**
 * Axios Wrapper
 *
 * @param props
 * @returns {Promise}
 */
const fetchAx = (props) => {
    const resProps = {
        ...props,
        headers: {
            'Content-Type': 'application/json',
            ...props.headers,
        },
    };

    return axios(resProps);
}

axios.interceptors.response.use((response) => response,  async (error) => {
    return Promise.reject(error);
});

export default fetchAx;
