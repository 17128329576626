import React, { useState } from "react";
import "../TermsUsingCredit/TermsUsingCredit.scss";

const WarningCredit = () => {
    const [showTerm, setShowTerm] = useState(false);

    return (
        <div className="terms_using_credit">
            <div className="container">
                <div className="title">ПОПЕРЕДЖЕННЯ</div>
                <div className="terms_using_credit__wrapper">
                    <div className={`terms_using_credit__info ${showTerm ? 'active' : ''}`}>
                        Кредитодавець попереджає Споживача про те, що:
                        <br/>
                        <br/>
                        -	В разі невиконання обовʼязків згідно з Договором про споживчий кредит
                        до Споживача можуть бути застосовані штраф/пеня, а також інші платежі передбачені Договором про споживчий кредит;
                        <br/>
                        <br/>
                        -	Порушення виконання зобов’язання щодо повернення споживчого кредиту
                        у передбачені договором про споживчий кредит строки, може вплинути на кредитну історію та ускладнити отримання  споживчого кредиту надалі;
                        <br/>
                        <br/>
                        -	Товариству з обмеженою відповідальністю «ГРОШІ 247» забороняється
                        вимагати від Споживача придбання будь-яких товарів чи послуг від Товариства з обмеженою відповідальністю «ГРОШІ 247» або спорідненої  чи повʼязаної з нею особи як обовʼязкову умову надання споживчого кредиту;
                        <br/>
                        <br/>
                        -   Для прийняття усвідомленого рішення щодо отримання споживчого  кредиту
                        на запропонованих умовах Споживач має право розглянути  альтернативні різновиди споживчих кредитів та фінансових установ;
                        <br/>
                        <br/>
                        -	Товариство з обмеженою відповідальністю «ГРОШІ 247» має право вносити
                        зміни до укладених зі Споживачами договорів про споживчий кредит тільки за взаємною згодою сторін у спосіб та на умовах визначених договором про споживчий кредит;
                        <br/>
                        <br/>
                        -	Споживач має право відмовитися від отримання рекламних матеріалів Товариства засобами дистанційних каналів комунікації, шляхом надсилання відповідного повідомлення Товариству;
                        <br/>
                        <br/>
                        -	Можливі витрати на сплату Споживачем платежів за користування споживчим кредитом в залежності від обраного Споживачем способу сплати;
                        <br/>
                        <br/>
                        -	Споживач має право на продовження (пролонгації) строку  погашення споживчого кредиту (строку виконання грошового зобов’язання)/строку кредитування/строку дії договору про споживчий кредит за згодою Товариства. Ці дії на стандартних умовах здійснюються в Особистому кабінеті Споживача, без змін або зі зміною умов попередньо укладеного договору в бік погіршення для Споживача із зазначенням переліку та цифрового значення умов, що підлягають зміні та залежить від виду договору про споживче кредитування. Для отримання індивідуальних умов продовження строку погашення споживчого кредиту, внесення інших змін в договір споживчого кредиту Позичальник повинен направити письмове звернення Товариству із зазначенням бажаних умов зміни умов кредитування. Товариство розглядає таке звернення відповідно до внутрішніх процедур Товариства і повідомляє клієнта про своє рішення.
                        <br/>
                        <br/>
                        Споживач повинен негайно інформувати Товариство про несанкціонований доступ або зміну інформації про себе в системах дистанційного обслуговування Товариства з обмеженою відповідальністю «ГРОШІ 247».
                    </div>
                    <div className="terms_using_credit__border" />
                    <button className="btn btn__pink" onClick={() => setShowTerm(!showTerm)}>
                        {showTerm ? 'Сховати' : 'Читати повністю'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WarningCredit;