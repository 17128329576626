import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoFooterIcon from "../../assets/logo_footer.png";
import phoneFooterIcon from "../../assets/phone_footer.png";
import mailFooterIcon from "../../assets/mail_footer.png";
import pinFooterIcon from "../../assets/pin_footer.png";
import timeFooterIcon from "../../assets/time_footer.png";
import visaLogoIcon from "../../assets/visa_logo.png";
import platonLogoIcon from "../../assets/logo-gor-black.png";
import prostirLogoIcon from "../../assets/prostir_logo.png";
import mcLogoIcon from "../../assets/mc_vrt_rev.svg";
import privacyPolicyFile from "../../files/privacy_policy.pdf";
import warningFile from "../../files/warning.pdf";
import agreementAboutConsumerCreditFile from "../../files/Договір_про_споживчий_кредит_оновлений_відповідно_до_вимог_положення.pdf";
import guarantorContractFile from "../../files/guarantor_contract.pdf";
import regulationsOnLendingFile from "../../files/regulations_on_lending_to_legal_entities.pdf";
import schemeOnSiteMoneyFile from "../../files/схема_на_сайт_Гроші.pdf";
import consumerCreditPassportFile from "../../files/Паспорт_споживчого_кредиту.pdf";
import aboutInformingConsumersFile from "../../files/Про_інформування_споживачів.pdf";
import procedureForConsiderationFile from "../../files/Процедура_розгляду_звернень_споживачів.pdf";
import procedureForRegulatoryOverdueDebtFile from "../../files/Порядок,_спосіб_та_умови_врегулювання_простроченої_заборгованості.pdf";
import rulesForLendingFundsFile from "../../files/rules_for_lending_funds.pdf";
import informationAboutTheFinalFile from "../../files/відомості_про_остаточних.pdf";
import mechanismsForTheProtectionFile from "../../files/Механізми_захисту_прав_споживачів_ТОВ_ГРОШІ247.pdf";
import "./Footer.scss";
import PhoneComponent from "../Common/PhoneComponent";
import Portal from "../Portal";
import FeedbackForm from "../Forms/FeedbackForm";


const Footer = () => {
    const [isModalActive, setModalActive] = useState(false)
    const [modalType, setModalType] = useState(null)

    const toggleModal = (type) => {
        setModalActive((prev) => (!prev));
        setModalType(type)
    }

    return (
        <footer className="footer">
            <div className="footer_background">
                <div className="container">
                    <div className="footer__top">
                        <div className="footer__info">
                            <div className="footer__logo">
                                <img src={logoFooterIcon} alt="Footer logo" />
                            </div>
                            <div className="footer__text">
                                "Гроші 24\7" є компанією, що надає послуги мікрокредитування в Україні. Клієнти можуть отримати кредит онлайн на різні потреби, такі як непередбачувані витрати, погашення боргів або придбання товарів. Компанія заявляє, що дотримується прозорої та відповідальної політики кредитування та завжди готова допомогти своїм клієнтам. "Гроші 24\7" також активно розвивається та впроваджує нові технології, щоб зробити процес отримання кредиту ще більш зручним та швидким.
                            </div>
                            <div className="footer__text">
                                <img src={phoneFooterIcon} alt="Footer phone icon" />
                                <PhoneComponent />
                            </div>

                            <div className="footer__text">
                                <img src={mailFooterIcon} alt="Footer mail icon" />
                                info@groshi247.com
                            </div>

                            <div className="footer__text">
                                <img src={pinFooterIcon} alt="Footer pin icon" />
                                03061, М.КИЇВ, ВУЛИЦЯ ГЕРОЇВ СЕВАСТОПОЛЯ, БУДИНОК 48, КІМНАТА 2
                            </div>

                            <div className="footer__text">
                                <img src={timeFooterIcon} alt="Footer time icon" />
                                Час роботи: видача кредитів цілодобово без вихідних.
                                Час роботи офісу: пн.-пт. 8-30 – 20-30, cб. 8-30 – 16-30.
                                Неділя - вихідний
                            </div>

                            <div className="footer__text">
                                Усі способи звʼязку, зазначені вище, доступні для захищеної категорії осіб
                            </div>

                            <div className="btn__group">
                                <button onClick={() => toggleModal('complaint')} className="btn btn__pink">Залишити скаргу</button>
                                <button onClick={() => toggleModal('support')} className="btn btn__green">Підтримка</button>
                            </div>

                            {/*<div className="footer__socials">*/}
                            {/*    <a href="#">*/}
                            {/*        <img src={instagramIcon} alt="Social icon instagram" />*/}
                            {/*    </a>*/}
                            {/*    <a href="#">*/}
                            {/*        <img src={telegramIcon} alt="Social icon telegram" />*/}
                            {/*    </a>*/}
                            {/*    <a href="#">*/}
                            {/*        <img src={facebookIcon} alt="Social icon facebook" />*/}
                            {/*    </a>*/}
                            {/*    <a href="#">*/}
                            {/*        <img src={whatsappIcon} alt="Social icon whatsapp" />*/}
                            {/*    </a>*/}
                            {/*</div>*/}
                        </div>
                        <div className="footer__list">
                            <a href="/about-us" className="footer__item">
                                Публічна інформація
                            </a>
                            <Link to={consumerCreditPassportFile} target="_blank" download className="footer__item">
                                Паспорт споживчого кредиту
                            </Link>
                            <Link to={rulesForLendingFundsFile} className="footer__item" target="_blank" download>
                                Правила надання послуги
                            </Link>
                            <Link to={guarantorContractFile} target="_blank" download className="footer__item">
                                Договір поручителя
                            </Link>
                            <Link to={regulationsOnLendingFile} target="_blank" download className="footer__item">
                                Положення про кредитування юридичних осіб
                            </Link>
                            <Link to={agreementAboutConsumerCreditFile} target="_blank" download className="footer__item">
                                Договір про споживчий кредит
                            </Link>
                            <Link to={privacyPolicyFile} target="_blank" download className="footer__item">
                                Політика конфіденційності
                            </Link>
                            <Link to={informationAboutTheFinalFile} target="_blank" download className="footer__item">
                                Структура власності
                            </Link>
                            <Link to={schemeOnSiteMoneyFile} className="footer__item" target="_blank" download>
                                Схема власності
                            </Link>
                            <Link to={procedureForRegulatoryOverdueDebtFile} className="footer__item" target="_blank" download>
                                Порядок, спосіб та вимоги врегулювання простроченої заборгованості
                            </Link>
                            <Link to={procedureForConsiderationFile} target="_blank" className="footer__item" download>
                                Процедура розгляду звернень споживачів
                            </Link>
                            <Link to={warningFile} target="_blank" className="footer__item">
                                Попередження
                            </Link>
                            <Link to={aboutInformingConsumersFile} target="_blank" download className="footer__item">
                                Про інформування споживачів
                            </Link>
                            <Link to={mechanismsForTheProtectionFile} target="_blank" download className="footer__item">
                                Механізм захисту прав споживачів
                            </Link>
                            <a href="#" className="footer__item">
                                Банківські реквізити
                            </a>
                        </div>
                    </div>
                    <div className="footer__bottom">
                        <div className="footer__block">
                            <div style={{ whiteSpace: 'nowrap' }}>
                                © Groshi 24\7
                            </div>
                            <div className="bank_card">
                                <div className="bank_card__item">
                                    <img src={platonLogoIcon} alt="platon logo" />
                                </div>
                                <div className="bank_card__item">
                                    <img src={mcLogoIcon} alt="master card logo" />
                                </div>
                                <div className="bank_card__item">
                                    <img src={prostirLogoIcon} alt="postir logo" />
                                </div>
                                <div className="bank_card__item" style={{ alignItems: 'center' }}>
                                    <img style={{ height: '25px' }} src={visaLogoIcon} alt="visa logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isModalActive &&
                <Portal
                    variant='wide'
                    onClose={() => setModalActive(false)}>
                    <div className="feedback_modal">
                        <h2 className="feedback_modal__title">
                            {modalType === 'support'
                                ? "Форма зворотного зв’язку"
                                : "Форма пропозицій або скарг"
                            }
                        </h2>
                        <p className="feedback_modal__text">
                            Дякуємо, що обрали нашу компанію.
                            Ваші відгуки для нас мають велике значення.
                            Якщо у вас виникли пропозиції або скарги, будь ласка, заповніть форму нижче.
                            Ми детально розглянемо кожне звернення і докладемо максимум зусиль, щоб знайти найкраще рішення для вашої ситуації.
                        </p>
                        <FeedbackForm variant={modalType} />
                        <div className="feedback_modal__contacts">
                            <span>Безкоштовні дзвінки по Україні з мобільних операторів і стаціонарних телефонів</span>
                            <PhoneComponent />
                        </div>
                    </div>
                </Portal>
            }
        </footer>
    );
};

export default Footer;