import { Link } from 'react-router-dom';

const ScrollLink = ({ to, children, className }) => {
    const handleClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <Link to={to} className={className} onClick={handleClick}>
            {children}
        </Link>
    );
};

export default ScrollLink;