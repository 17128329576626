import React from 'react'

import Footer from '../components/Footer/Footer'
import FAQ from '../components/FAQ/FAQ'
import StudyLoanWhyProfitableTakeLoan from './components/StudyLoan/StudyLoanWhyProfitableTakeLoan'
import StudyLoanToGetLoan from './components/StudyLoan/StudyLoanToGetLoan'
import StudyLoanCalculate from './components/StudyLoan/StudyLoanCalculate'
import ApplicationForm from '../components/Forms/ApplicationForm'
import StudyLoanLeaveRequest from './components/StudyLoan/StudyLoanLeaveRequest'
import 'swiper/css'

const StudyLoan = () => {
    return (
        <div className="study_loan">
            {/* Calculator */}
            <StudyLoanCalculate />

            {/* Why is it profitable to take a loan? */}
            <StudyLoanWhyProfitableTakeLoan />

            {/* Application form */}
            <ApplicationForm />

            {/* To get a loan */}
            <StudyLoanToGetLoan />

            <StudyLoanLeaveRequest />

            <FAQ />

            <Footer />
        </div>
    );
};

export default StudyLoan;