import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { getCreditPolicies, postPaymentScheduleCalculate, postShopRequest } from "../api/loanCalculate";
import moment from "moment";

const useCalculate = ({ creditProductId = 4, needPassword = false, sendCalculate = true, typeSendLoanTerm = 'day' }) => {
    const [creditPolicies, setCreditPolicies] = useState(null);

    const [loanAmount, setLoanAmount] = useState(0);
    const [loanTerm, setLoanTerm] = useState(0);
    const [promocode, setPromocode] = useState('');

    const [password, setPassword] = useState('');

    const [paymentScheduleCalculate, setPaymentScheduleCalculate] = useState([]);
    const [apr, setApr] = useState(0);
    const [interest, setInterest] = useState(0);
    const [creditPolicyInterest, setCreditPolicyInterest] = useState(0);
    const [principal, setPrincipal] = useState(0);
    const [total, setTotal] = useState(0);

    const getCalculateLoanAmount = async ({
                                              credit_policy_id,
                                              amount,
                                              loan_term,
                                              category_id,
                                              promo_code
                                          }) => {
        const data = {
            credit_policy_id,
            amount,
            loan_term,
            category_id,
            promo_code: !!promo_code?.length ? promo_code : null
        }

        if (needPassword && password === 'Hs212Tp22') {}

        await postPaymentScheduleCalculate(data).then((res) => {
            let aprTemp = 0
            let commissionTemp = 0
            let interestTemp = 0
            let principalTemp = 0
            let totalTemp = 0

            res.data.forEach((calculateLoanAmount) => {
                principalTemp += +calculateLoanAmount.principal
                interestTemp += +calculateLoanAmount.interest
                commissionTemp += +calculateLoanAmount.commission
            })

            totalTemp = +principalTemp + +interestTemp + +commissionTemp

            const schemaFormula = creditPolicies && creditPolicies?.percent_accrual_schema === 1 ? 365 : 360

            if(loanTerm) aprTemp = (((interestTemp + commissionTemp) / principalTemp) / loanTerm * schemaFormula) * 100

            setPrincipal(principalTemp)
            setInterest(interestTemp)
            setApr(aprTemp)
            setTotal(totalTemp)

            setPaymentScheduleCalculate(res.data)

        }).catch((err) => {})
    }

    const getLoanAmountWithDelay = useDebouncedCallback(async () => {
        if (creditPolicies && loanAmount && loanTerm) {
            switch (needPassword) {
                case true: {
                    if (password === 'Hs212Tp22') {
                        await getCalculateLoanAmount({
                            credit_policy_id: creditPolicies?.id,
                            amount: loanAmount,
                            loan_term: typeSendLoanTerm === 'month'
                                ? moment(new Date()).add(loanTerm, 'M').diff(moment(new Date()))
                                : loanTerm,
                            category_id: 1,
                            promo_code: promocode
                        })
                    }
                    break
                }
                case false: {
                    await getCalculateLoanAmount({
                        credit_policy_id: creditPolicies?.id,
                        amount: loanAmount,
                        loan_term: typeSendLoanTerm === 'month'
                            ? moment(new Date()).add(loanTerm, 'M').diff(moment(new Date()))
                            : loanTerm,
                        category_id: 1,
                        promo_code: promocode
                    })
                    break
                }
            }
        }
    }, 1000);

    const sendPromoCodeLoan = async () => {
        if (creditPolicies && loanAmount && loanTerm) {
            switch (needPassword) {
                case true: {
                    if (password === 'Hs212Tp22') {
                        await getCalculateLoanAmount({
                            credit_policy_id: creditPolicies?.id,
                            amount: loanAmount,
                            loan_term: typeSendLoanTerm === 'month'
                                ? moment(new Date()).add(loanTerm, 'M').diff(moment(new Date()), 'days')
                                : loanTerm,
                            category_id: 1,
                            promo_code: promocode
                        })
                    }
                    break
                }
                case false: {
                    await getCalculateLoanAmount({
                        credit_policy_id: creditPolicies?.id,
                        amount: loanAmount,
                        loan_term: typeSendLoanTerm === 'month'
                            ? moment(new Date()).add(loanTerm, 'M').diff(moment(new Date()), 'days')
                            : loanTerm,
                        category_id: 1,
                        promo_code: promocode
                    })
                    break
                }
            }
        }
    }

    const createShopRequest = async () => {
        await postShopRequest({
            product_name: 'Money on card',
            credit_policy_id: creditPolicies?.id,
            product_price: +loanAmount,
            loan_term: loanTerm,
            product_category_id: 1,
            shop_id: 1,
            client_name: 'Empty',
            promo_code: promocode
        }).then((res) => {
            if(window.fbq) {
                window.fbq('trackCreditAmount', 'Purchase', { currency: "UAH", value: +loanAmount })

                if (!!promocode?.length) {
                    window.fbq('trackCreditPromoCode', 'ShareDiscount', {promotion: promocode})
                }
            }

            window.location.href = res.data.url
        })
    }

    useEffect(() => {
        if (!sendCalculate) return
        if (creditPolicies && loanAmount && loanTerm) {
            getLoanAmountWithDelay()
        }
    },[creditPolicies, loanAmount, loanTerm, getLoanAmountWithDelay, sendCalculate]);

    useEffect(() => {
        const getCredits = async () => {
            await getCreditPolicies().then((res) => {
                if (!!res?.data?.length) {
                    const findCreditPolicy = res?.data?.find(policy => policy.id === creditProductId)

                    if (findCreditPolicy) {
                        setLoanAmount(findCreditPolicy?.loan_amount_limit_from)
                        if (typeSendLoanTerm === 'month') {
                            if (findCreditPolicy?.loan_term_limit_from < 30) {
                                setLoanTerm(1)
                            } else {
                                setLoanTerm(findCreditPolicy?.loan_term_limit_from / 30)
                            }
                        } else {
                            setLoanTerm(findCreditPolicy?.loan_term_limit_from)
                        }
                        setCreditPolicies(findCreditPolicy)
                        setCreditPolicyInterest(findCreditPolicy?.interest)
                    }
                }
            }).catch((err) => {})
        }

        getCredits()
    }, [])

    useEffect(() => {
        if (!sendCalculate) return
        if (creditPolicies && loanAmount && loanTerm && password === 'Hs212Tp22') {
            getLoanAmountWithDelay()
        }
    }, [creditPolicies, loanAmount, loanTerm, password, getLoanAmountWithDelay, sendCalculate])

    return {
        creditPolicies, setLoanAmount, setLoanTerm, apr, setApr, interest, setInterest, creditPolicyInterest,
        principal, setPrincipal, total, setTotal, createShopRequest, promocode, setPromocode, sendPromoCodeLoan, loanTerm,
        password, setPassword, paymentScheduleCalculate
    }
}

export default useCalculate