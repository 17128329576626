import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Button from "../components/Common/Button";
import loyaltyProgramBonusUpFile from "../files/Офіційні_правила_програми_лояльності_BonusUP.pdf";

const LoyaltyProgram = () => {
    return (
        <div className="loyalty_program">
            <div className="top_background">
                <div className="container">
                    <Header activePageName="loyalty-program" />
                    <div className="loyalty_program__wrapper">
                        <div className="title">Програма лояльності</div>
                        <div className="faq__list">
                            <div className="faq__item">
                                <div className="faq__header">Хто може стати учасником програми лояльності</div>
                                <div className="faq__body">
                                    Всі клієнти, які є клієнтами ТОВ «Гроші 247», зареєстровані у Програмі «Мій кабінет» та підписали перший Договір позики, автоматично стають учасниками Програми лояльності «BonusUP».<br />
                                    В момент набуття статусу Учасника програми лояльності «BonusUP» клієнту відкривається бонусний рахунок для обліку нарахованих і витрачених бонусів. Активація бонусного рахунку відбувається автоматично.<br />
                                    Кожен Учасник може мати тільки один бонусний рахунок в Програмі лояльності «BonusUP».<br />
                                    Програма лояльності не має індивідуального (персонального) характеру по відношенню до учасників програми.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Нарахування бонусів за Програмою</div>
                                <div className="faq__body">
                                    Розрахунковою одиницею Програми лояльності «BonusUP» є бонуси. Співвідношення бонуса до гривні складає: 1 (один) бонус прирівнюється до 1 (однієї) грн.<br />
                                    Бонуси нараховуються пiсля закриття договору (повного погашення позики), згiдно правил нарахування в залежностi вiд суми фактичних витрат Клієнта. Наступного дня пiсля дня погашення позики нарахований бонус доступний для обмiну.<br />
                                    Термін дії кожного бонуса 3 (три) місяці. На наступний день по закінченню вказаного терміна бонуси згоряють.<br />
                                    Учасник не може отримати бонуси в грошовому еквіваленті в готівковій чи безготівковій формі.<br />
                                    Рахунок Учасника і нараховані бонуси не можуть бути об’єднані з рахунками і бонусами інших Учасників Програми лояльності «BonusUP».<br />
                                    Учасник Програми лояльності «BonusUP», має право на отримання бонусів за виконання наступних дій:<br />
                                    <br />
                                    <div className="loyalty_program_wrapper_text">
                                        <div>
                                            <div style={{ textAlign: 'center' }}>
                                                <b>Дія</b>
                                            </div>
                                            <div className="loyalty_program_wrapper_text__col">
                                                <div>Користування позикою з дотриманням умов Договору позики (вчасний розрахунок за кредитом та відсотками)</div>
                                                <div>За реєстрацію в Програмі «Мій кабінет»</div>
                                                <div>В День народження Клієнта</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ textAlign: 'center' }}>
                                                <b>Кількість бонусів та умови нарахування</b>
                                            </div>
                                            <div className="loyalty_program_wrapper_text__col">
                                                <div>3% від суми витрат.</div>
                                                <div>Нараховується одноразово в момент реєстрацii в програмi «Мiй кабiнет» в розмiрi 10 бонусiв і діють протягом 1 (одного) місяця з моменту регістрації.</div>
                                                <div>Нараховується один раз на рік в День народження Клієнта – в дату, яка вказана в реєстраційних даних як день народження, в розмірі 30 бонусів.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    Бонусна система є накопичувальною. Нарахування бонусів на бонусний рахунок Клієнта відбувається автоматично.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Використання бонусів</div>
                                <div className="faq__body">
                                    Учасник може використати нараховані бонуси до моменту їх  згоряння в програмі «Мій кабінет»<br />
                                    Використання бонусів Учасником можливе на:<br />
                                    - Послуги ТОВ «Гроші 247»;<br />
                                    - Послуги Партнерів;<br />
                                    Використання бонусiв на послуги ТОВ «Гроші 247» – це обмiн бонусiв учасників  Програми лояльності в рахунок часткового або повного погашення суми витрат Клієнта (відсотків) за позикою. Можливість використання наявних бонусів розповсюджується на всіх учасників програми лояльності без окремої персоналізації Клієнтів і додаткових вимог, або інших обмежень.<br />
                                    Обмiн бонусiв на послуги партнерiв – це обмiн бонусiв на знижку на  послугу вiповiдного партнера ТОВ «Гроші 247» зi списку (за діючими партнерськими  програмами).

                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 16 }}>
                            <Link to={loyaltyProgramBonusUpFile} target="_blank" download>
                                <Button pink>Правила Програми лояльності</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default LoyaltyProgram;