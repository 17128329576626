import { REACT_APP_SEND_EMAIL_TOKEN, MAIL_RECIPIENT, MAIL_SENDER } from '../../const/api'
import { getFileToMessage } from './convertFile'


export const sendApplicationEmail = async (data) => {
    if (!window.Email) return console.error('Email service not available');

    const body = ` <html>
            <body>
                <h2>Заявка через сайт</h2>
                <p><strong>Ім'я:</strong> ${data.name}</p>
                <p><strong>Телефон:</strong> ${data.phone}</p>
                <p><strong>Електронна пошта:</strong> ${data.email}</p>
                <p><strong>Згода з умовами:</strong> ${data.agree ? 'Так' : 'Ні'}</p>
                <p><strong>Згода на рекламу:</strong> ${data.agreeAds ? 'Так' : 'Ні'}</p>
            </body>
            </html>
            `

    try {
        return await window.Email.send({
            SecureToken: REACT_APP_SEND_EMAIL_TOKEN,
            To: MAIL_RECIPIENT,
            From: MAIL_SENDER,
            Subject: `[Заявка через сайт] від ${data.email}`,
            Body: body,
        });
    } catch (error) {
        console.error('Ошибка при отправке email:', error);
        return error;
    }
};

export const sendFeedbackEmail = async (data, type) => {
    if (!window.Email) return console.error('Email service not available');
    if (!data) return console.error('Feedback form error');

    const messageTitle = `[${type === 'support' ? "Підтримка" : "Скарга"}] ІПН ${data.ipn}`
    const body = ` <html>
            <body>
                <h2>Форма зворотнього зв'язку</h2>
                <p><strong>Ім'я:</strong> ${data.name}</p>
                <p><strong>Телефон:</strong> ${data.phone}</p>
                <p><strong>Електронна пошта:</strong> ${data.email}</p>
                <p><strong>ІПН</strong> ${data.ipn}</p>
                <p><strong>Повідомлення</strong> ${data.message}</p>
            </body>
            </html>
            `
    const attachments = await getFileToMessage(data.file)

    try {

        return await window.Email.send({
            SecureToken: REACT_APP_SEND_EMAIL_TOKEN,
            To: MAIL_RECIPIENT,
            From: MAIL_SENDER,
            Subject: messageTitle,
            Body: body,
            Attachments: attachments,

        });
    } catch (error) {
        console.error('Ошибка при отправке email:', error);
        return error;
    }
};

export const sendStudyLoanApplicationEmail = async (data) => {
    if (!window.Email) return console.error('Email service not available');

    const body = ` <html>
            <body>
                <h2>Заявка через сайт</h2>
                <p><strong>Ім'я:</strong> ${data.name}</p>
                <p><strong>Телефон:</strong> ${data.phone}</p>
                <p><strong>Електронна пошта:</strong> ${data.email}</p>
                <p><strong>Тип:</strong> ${data.type}</p>
            </body>
            </html>
            `

    try {
        return await window.Email.send({
            SecureToken: REACT_APP_SEND_EMAIL_TOKEN,
            To: MAIL_RECIPIENT,
            From: MAIL_SENDER,
            Subject: `[Заявка через сайт] від ${data.email}`,
            Body: body,
        });
    } catch (error) {
        console.error('Ошибка при отправке email:', error);
        return error;
    }
};

