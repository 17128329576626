import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import how_to_get_a_loan_for_studying from "../assets/blog/how_to_get_a_loan_for_studying.jpg";
import loan_for_learn_language from "../assets/blog/loan_for_learn_language.jpg";
import blogImg1 from "../assets/blog_img_1.png";
import blogImg2 from "../assets/blog_img_2.png";
import blogImg3 from "../assets/blog_img_3.png";
import blogImg4 from "../assets/blog_img_4.png";
import blogImg5 from "../assets/blog_img_5.png";
import blogImg6 from "../assets/blog_img_6.png";
import TermsUsingCredit from "../components/TermsUsingCredit/TermsUsingCredit";
import ScrollLink from "../lib/helpers/ScrollLink";

const Blog = () => {
    return (
        <div className="blog">
            <div className="top_background">
                <div className="container">
                    <Header activePageName="blog" />
                    <div className="blog__wrapper">
                        <div className="title">Блог</div>
                        <div className="blog__list">
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img
                                        src={loan_for_learn_language}
                                        alt="loan for learn language"
                                    />
                                </div>
                                <div className="blog__title">
                                    Навчання мовному курсу
                                </div>
                                <div className="blog__desc">
                                    У сучасному світі, де глобалізація та міжнародні зв'язки стають дедалі важливішими, знання іноземних мов відкриває безліч нових можливостей.
                                </div>
                                <ScrollLink to="/blog/loan-learn-language" className="btn btn__pink">Читати далі</ScrollLink>
                            </div>
                            <div className="blog__item">
                                <div className="blog__img">
                                    <img
                                        src={how_to_get_a_loan_for_studying}
                                        alt="how to get a loan for studying"
                                    />
                                </div>
                                <div className="blog__title">
                                    Навчання комп'ютерного курсу
                                </div>
                                <div className="blog__desc">
                                    У сучасному світі цифрових технологій, комп'ютерна грамотність стає невід'ємною частиною успішної кар'єри та особистого розвитку.
                                </div>
                                <ScrollLink to="/blog/loan-for-studying" className="btn btn__pink">Читати далі</ScrollLink>
                            </div>
                        </div>
                        {/* <div className="blog__btn">
                            <button className="btn btn__green">Більше статей</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <TermsUsingCredit />
            <Footer />
        </div>
    );
};

export default Blog;