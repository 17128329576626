import {
    CREDIT_POLICIES_GET, PAYMENT_SCHEDULE_CALCULATE_POST, SHOP_REQUEST_POST
} from "../../const/api";
import fetchAx from '../fetchAx';

export const getCreditPolicies = (params) => fetchAx({
    url: CREDIT_POLICIES_GET,
    method: 'GET',
    params: {
        ...params,
    },
});

export const postPaymentScheduleCalculate = (data, params) => fetchAx({
    url: PAYMENT_SCHEDULE_CALCULATE_POST,
    method: 'POST',
    params: {
        ...params,
    },
    data
});

export const postShopRequest = (data, params) => fetchAx({
    url: SHOP_REQUEST_POST,
    method: 'POST',
    params: {
        ...params,
    },
    data
});
