import React from 'react';
import Header from "../../components/Header/Header";
import ScrollLink from "../../lib/helpers/ScrollLink";
import TermsUsingCredit from "../../components/TermsUsingCredit/TermsUsingCredit";
import Footer from "../../components/Footer/Footer";

const InterestRate = () => {
    return (
        <div className="stock">
            <div className="top_background" style={{ backgroundImage: 'none' }}>
                <div className="container">
                    <Header activePageName="stock" />
                    <div className="blog__wrapper">
                        <h1 className="title">- 50% на процентну ставку по кредиту</h1>
                        <div className="blog__item" style={{ width: '100%' }}>
                            <div className="blog__desc">
                                Хочемо нагадати, що акція "ГРОШОВА ОСІНЬ"діє до 30 листопада 2024 року!
                                <br />
                                <br />
                                Скористайтеся "зігріваючою" пропозицію від Groshi 24/7 та отримайте позику до 6000 грн на картку зі знижкою - 50%. В ці холодні похмурі дні, коли не хочеться навіть виходити з дому, ви можете оформити кредит онлайн!
                                <br />
                                <br />
                                Подання заявки на споживчий кредит, ідентифікація в системі, підписання договору, - все це займе лічені хвилини. Переходьте на сторінку <ScrollLink to="/">https://groshi247.com/</ScrollLink> та переконайтеся.<br />
                                <br />
                                Groshi 24/7 - сервіс, який виручить у будь-який момент, коли забракне коштів.
                                <br />
                                <br />
                                __
                                <br />
                                Акція діє до 30.11.2024 р. на всій підконтрольній Україні території, окрім тимчасово окупованих та оточених регіонів, а також районів, де ведуться бойові дії.
                                <br />
                                <a href="https://cutt.ly/RebIfU0W" target="_blank">Інформація про істотні характеристики послуги з надання мікрокредитів</a> та <a href="https://cutt.ly/henryljy" target="_blank">Попередження про невиконання умов договору кредитування</a>.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TermsUsingCredit />
            <Footer />
        </div>
    );
};

export default InterestRate;