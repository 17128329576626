import React from 'react';

const StudyLoanToGetLoan = () => {
	return (
		<div className="study_loan_to_get_a_loan">
			<div className="study_loan_to_get_a_loan__wrapper">
				<div className="container">
					<div className="title">
						Для отримання кредиту<br />
						на навчання необхідно:
					</div>

					<div className="study_loan_to_get_a_loan__list">
						<div className="study_loan_to_get_a_loan__block">
							<div className="study_loan_to_get_a_loan__item">досягти 18-річчя</div>
							<div className="study_loan_to_get_a_loan__item">заповнити анкету<br />прямо тут на сайті</div>
							<div className="study_loan_to_get_a_loan__item">додати до анкети підтвердження про зарахування до навчального закладу або реєстрацію на навчальні курси</div>
						</div>
						<div className="study_loan_to_get_a_loan__block">
							<div className="study_loan_to_get_a_loan__item">мати рахунок в українському банку</div>
							<div className="study_loan_to_get_a_loan__item">для кредиту потрібен Гарантор - це можуть бути ваші батьки чи друзі</div>
							<div className="study_loan_to_get_a_loan__item">додати до анкети рахунок на оплату навчального семестру або навчальних курсів</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StudyLoanToGetLoan;